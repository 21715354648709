import PropTypes from "prop-types";
import React from "react";

function Button({ children }) {
  return (
    <button
      type="button"
      className="bg-gradient-to-r from-secondary to-secondary-accent hover:from-secondary-accent hover:to-secondary py-2 px-3 rounded text-white font-medium	"
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
