import React from "react";

import Layout from "../layouts/defaultLayout";
import SEO from "../components/seo";
import { useIntl, Link } from "gatsby-plugin-intl";
import Button from "../components/button";

function NotFoundPage() {
  const intl = useIntl()

  return (
    <Layout footer={false} header={false}>
      <SEO title="404: Not found" />
      <div className="text-center">
        <h2 className="text-white text-2xl font-bold my-8 p-3">
          {intl.formatMessage({ id: 'not_found'})}
        </h2>
        <p className="text-gray-100 mb-8">
          {intl.formatMessage({ id: 'not_found_explanation'})}
        </p>
        <Button>
          <Link to='/'>
            {intl.formatMessage({ id: 'go_to_home'})}
          </Link>
        </Button>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
